@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

body, html {
	height: 100%;
	width: 100%;
	background-color: #171717;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
